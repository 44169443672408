import React from "react"
import Layout from "../components/layout"

export default function Page404() {
  return (
    <Layout>
      <p>404</p>
    </Layout>
  )
}
